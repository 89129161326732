import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import DefaultInfoCard from "components/Cards/InfoCards/DefaultInfoCard";
import FilledInfoCard from "../../../components/Cards/InfoCards/FilledInfoCard";
import ProductListView from "../../../components/Cards/InfoCards/ServiceInfoCard";

import InjectPaiting from "../../../assets/images/service/inject_printing.jpg"

function Product() {
  return (
    <MKBox bgColor= "grey-200" component="section" py={6} my={0}>
      <Container> 
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
        >
          <MKTypography variant="h4" fontWeight="bold" color="white">
           Top Products
          </MKTypography>
        </Grid>
        < ProductListView />
      </Container>
    </MKBox>
  );
}

export default Product;
