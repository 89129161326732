import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import MuiLink from "@mui/material/Link";
import { Link } from "react-router-dom";

import DefaultInfoCard from "components/Cards/InfoCards/DefaultInfoCard";
import FilledInfoCard from "../../../components/Cards/InfoCards/FilledInfoCard";
import ProductListView from "../../../components/Cards/InfoCards/ServiceInfoCard";

import InjectPaiting from "../../../assets/images/service/inject_printing.jpg"
import Event3 from "../../../assets/images/service/Event3.png"
import Event4 from "../../../assets/images/service/Event4.png"
import Event5 from "../../../assets/images/service/Event5.png"
import Event6 from "../../../assets/images/service/Event6.png"
import Event7 from "../../../assets/images/service/Event7.png"
import Event8 from "../../../assets/images/service/Event8.png"
import Event9 from "../../../assets/images/service/Event9.png"
import Event10 from "../../../assets/images/service/Event10.png"
import Event11 from "../../../assets/images/service/Event11.png"
import Event12 from "../../../assets/images/service/Event12.png"


function Service() {
  return (
    <MKBox bgColor= "grey-100" component="section" py={6} my={0}>
      <Container> 
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
        >
          <MKTypography variant="h4" fontWeight="bold">
           Services
          </MKTypography>
          <MKTypography variant="body2" color="dark" opacity={0.8} mt={2}>
           No matter what your needs are, you can count on helloprint for the support you deserve. We see each customer as unique, which is why all of our services can be completely personalized to fit specific needs and preferences. Browse through our comprehensive range of services below to learn more about what we can do for you.
          </MKTypography>

        </Grid>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={12} md={12} sx={{ ml: "auto" }}>
            <Grid 
              container 
              spacing={3}  
              alignItems="center"
              justify="center"
              >
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  image={Event9}
                  icon="content_copy"
                  title=""
                  color="warning"
                  direction="center"
                  description="Built by developers for developers. Check the foundation and you will find
                    everything inside our documentation."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard 
                  image={Event10}
                  icon="flip_to_front"
                  title=""
                  color="success"
                  direction="center"
                  description="The world's most popular react components library for building user interfaces."
                />
              </Grid>
            </Grid>
            <Grid 
              container 
              spacing={3} 
              sx={{ mt: { xs: 0, md: 3 } }} 
              >
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  image={Event11}
                  icon="price_change"
                  title=""
                  color="error"
                  direction="center"
                  description="Creating your design from scratch with dedicated designers can be very expensive. Start with our Design System."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard 
                  image={Event12}
                  icon="flip_to_front"
                  title=""
                  color="success"
                  direction="center"
                  description="The world's most popular react components library for building user interfaces."
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <MKBox
               display="flex"
               justifyContent="center"
               alignContent="center"
               width="100%"
               >

              <Link to="/pages/pages/service">
                <MKButton
                  component={MuiLink}
                  target="_blank"
                  rel="noreferrer"
                  variant="gradient"
                  size="large"
                  color="secondary"
                  circular="true"
                  display="flex"
                  sx={{ mt:5 }}
                  >
                  
                  <MKTypography 
                      variant="h7" 
                      color="dark" >
                      View All
                  </MKTypography>
                </MKButton>
              </Link>
 
            </MKBox>
      </Container>
    </MKBox>
  );
}

export default Service;
