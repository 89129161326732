import * as React from 'react';
import PropTypes from "prop-types";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import MKBox from "components/MKBox";
import Button from '@mui/material/Button';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKTypography from "components/MKTypography";
import { Link } from "react-router-dom";

import TransparentBlogCard from "components/Cards/BlogCards/TransparentBlogCard";
import BackgroundBlogCard from "components/Cards/BlogCards/BackgroundBlogCard";

import MuiLink from "@mui/material/Link";
import MKButton from "components/MKButton";

import Sticker from "../../../../assets/images/product/sticker.png"
import bag from "../../../../assets/images/product/bag.png"
import box from "../../../../assets/images/product/box.png"
import calender from "../../../../assets/images/product/calendar.png"
import diary from "../../../../assets/images/product/diary.png"
import mug from "../../../../assets/images/product/mug.png"
import sampul from "../../../../assets/images/product/sampul.png"
import signage from "../../../../assets/images/product/signage.png"
import businessCard from "../../../../assets/images/product/business_card.png"
import digitalOffset from "../../../../assets/images/product/digital_offset.png"
import displayItem from "../../../../assets/images/product/display_item.png"
import rubberStamp from "../../../../assets/images/product/rubber_stamp.png"


function ProductListView({ showAll }) {
    if (showAll){
        return (
            <MKBox
                display={{ xs: "block", md: "flex" }}
                borderRadius="xl"
                pt={3.5}
                pb={3}
                px={3}
            >
                <Container>
                    <Grid container spacing={3}>
                        {itemData.map((item) => (
                            <Grid item xs={12} sm={6} lg={3}>
                                <TransparentBlogCard
                                    image={item.img}
                                    title={item.title}
                                    description=""
                                    action={{
                                        type: "internal",
                                        route: "",
                                        color: "text",
                                        label: "",
                                    }}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </MKBox>
          );

    } else {
        return (
            <MKBox component="section" py={2}>
              <Container>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} lg={3}>
                    <TransparentBlogCard
                      image={digitalOffset}
                      title="Digital Offset"
                      description="Finding temporary housing for your dog should be as easy as renting an Airbnb. That’s the idea behind Rover ..."
                      action={{
                        type: "internal",
                        route: "",
                        color: "text",
                        label: "",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <TransparentBlogCard
                      image={box}
                      title="Box & Packaging"
                      description="If you’ve ever wanted to train a machine learning model and integrate it with IFTTT, you now can with ..."
                      action={{
                        type: "internal",
                        route: "",
                        color: "text",
                        label: "",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <TransparentBlogCard
                      image={displayItem}
                      title="Display Item Product"
                      description="If you’ve ever wanted to train a machine learning model and integrate it with IFTTT, you now can with ..."
                      action={{
                        type: "internal",
                        route: "",
                        color: "text",
                        label: "",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <TransparentBlogCard
                      image={signage}
                      title="Signage"
                      description="Rather than worrying about switching offices every couple years, you stay in the same place."
                      action={{
                        type: "internal",
                        route: "",
                        color: "text",
                        label: "",
                      }}
                    />
                  </Grid>
      
              
                    <MKBox
                      display="flex"
                      justifyContent="center"
                      alignContent="center"
                      width="100%"
                      >
                        <Link to="/pages/pages/product">
                            <MKButton
                                component={MuiLink}
                                target="_blank"
                                rel="noreferrer"
                                variant="gradient"
                                size="large"
                                color="secondary"
                                circular="true"
                                display="flex"
                                sx={{ mt:5 }}
                                >
                                
                                <MKTypography 
                                    variant="h7" 
                                    color="dark" >
                                    View All
                                </MKTypography>
                            </MKButton>
                        </Link>
                    </MKBox>
                 
                </Grid>
      
      
              </Container>
           
            </MKBox>
          );
    }
  
  }


const itemData = [
  {
    img: Sticker,
    title: 'Sticker Printing',
    author: '@bkristastucchio',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: signage,
    title: 'Business Card',
    author: '@rollelflex_graphy726',
  },
  {
    img: bag,
    title: 'Bag',
    author: '@helloimnik',
  },
  {
    img: box,
    title: 'Box & Packaging',
    author: '@nolanissac',
    cols: 2,
  },
  {
    img: rubberStamp,
    title: 'Rubber Stamp',
    author: '@hjrc33',
    cols: 2,
  },
  {
    img: displayItem,
    title: 'Display Item Product',
    author: '@arwinneil',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: signage,
    title: 'Signage',
    author: '@tjdragotta',
  },
  {
    img: digitalOffset,
    title: 'Digital Offset',
    author: '@katie_wasserman',
  },
  {
    img: diary,
    title: 'Planner Diary',
    author: '@silverdalex',
    rows: 2,
    cols: 2,
  },
  {
    img: calender,
    title: 'Calender',
    author: '@shelleypauls',
  },
  {
    img: mug,
    title: 'Mug',
    author: '@peterlaster',
  },
  {
    img: sampul,
    title: 'Sampul',
    author: '@southside_customs',
    cols: 2,
  },
];


ProductListView.defaultProps = {
    showAll: false,
  };
  
  // Typechecking props for the FilledInfoCard
ProductListView.propTypes = {
    showAll: PropTypes.bool.isRequired,
  };


export default ProductListView
