
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultReviewCard from "components/Cards/ReviewCards/DefaultReviewCard";
import DefaultNavbar from "components/Navbars/DefaultNavbar";
import DefaultFooter from "components/Footers/DefaultFooter";

import ProductListView from "../../components/Cards/InfoCards/ServiceInfoCard"

import routes from "routes";
import footerRoutes from "footer.routes";


function Product() {
  return (
    <>

    <MKBox variant="gradient" bgColor="white" shadow="sm" py={0.25}>
        <DefaultNavbar
          routes={routes}
          action={{
            type: "external",
            route: "",
            label: "cart",
            color: "warning",
          }}
          transparent
          relative
          center
        />
      </MKBox>
      <MKBox bgColor= "grey-200" component="section" py={6} my={0}>
        <Container> 
            <Grid
            container
            item
            xs={12}
            lg={6}
            flexDirection="column"
            alignItems="center"
            sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
            >
            <MKTypography variant="h4" fontWeight="bold" color="white">
                Products
            </MKTypography>
            </Grid>
            < ProductListView showAll={true}/>
        </Container>
        </MKBox>
        <MKBox pt={6} px={1} mt={6}>
           <DefaultFooter content={footerRoutes} />
        </MKBox>
    </>
  );
}

export default Product;
