
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import MuiLink from "@mui/material/Link";

import DefaultInfoCard from "components/Cards/InfoCards/DefaultInfoCard";

// Material Kit 2 React examples
import DefaultReviewCard from "components/Cards/ReviewCards/DefaultReviewCard";
import DefaultNavbar from "components/Navbars/DefaultNavbar";
import DefaultFooter from "components/Footers/DefaultFooter";

import Event9 from "../../assets/images/service/Event9.png"
import Event10 from "../../assets/images/service/Event10.png"
import Event11 from "../../assets/images/service/Event11.png"
import Event12 from "../../assets/images/service/Event12.png"
import Event13 from "../../assets/images/service/Event13.png"
import Event14 from "../../assets/images/service/Event14.png"
import Event15 from "../../assets/images/service/Event15.png"
import Event16 from "../../assets/images/service/Event16.png"

import routes from "routes";
import footerRoutes from "footer.routes";


function Service() {
  return (
    <>

    <MKBox variant="gradient" bgColor="white" shadow="sm" py={0.25}>
        <DefaultNavbar
          routes={routes}
          action={{
            type: "external",
            route: "",
            label: "cart",
            color: "warning",
          }}
          transparent
          relative
          center
        />
      </MKBox>
      <MKBox bgColor= "grey-100" component="section" py={6} my={0}>
      <Container> 
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
        >
          <MKTypography variant="h4" fontWeight="bold">
           Services
          </MKTypography>
          <MKTypography variant="body2" color="dark" opacity={0.8} mt={2}>
           No matter what your needs are, you can count on helloprint for the support you deserve. We see each customer as unique, which is why all of our services can be completely personalized to fit specific needs and preferences. Browse through our comprehensive range of services below to learn more about what we can do for you.
          </MKTypography>

        </Grid>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={12} md={12} sx={{ ml: "auto" }}>
            <Grid 
              container 
              spacing={3}  
              alignItems="center"
              justify="center"
              >
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  image={Event9}
                  icon="content_copy"
                  title=""
                  color="warning"
                  direction="center"
                  description="Built by developers for developers. Check the foundation and you will find
                    everything inside our documentation."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard 
                  image={Event10}
                  icon="flip_to_front"
                  title=""
                  color="success"
                  direction="center"
                  description="The world's most popular react components library for building user interfaces."
                />
              </Grid>
            </Grid>
            <Grid 
              container 
              spacing={3} 
              sx={{ mt: { xs: 0, md: 3 } }} 
              >
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  image={Event11}
                  icon="price_change"
                  title=""
                  color="error"
                  direction="center"
                  description="Creating your design from scratch with dedicated designers can be very expensive. Start with our Design System."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard 
                  image={Event12}
                  icon="flip_to_front"
                  title=""
                  color="success"
                  direction="center"
                  description="The world's most popular react components library for building user interfaces."
                />
              </Grid>
            </Grid>
            <Grid 
              container 
              spacing={3} 
              sx={{ mt: { xs: 0, md: 3 } }} 
              >
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  image={Event13}
                  icon="price_change"
                  title=""
                  color="error"
                  direction="center"
                  description="Creating your design from scratch with dedicated designers can be very expensive. Start with our Design System."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard 
                  image={Event14}
                  icon="flip_to_front"
                  title=""
                  color="success"
                  direction="center"
                  description="The world's most popular react components library for building user interfaces."
                />
              </Grid>
            </Grid>
            <Grid 
              container 
              spacing={3} 
              sx={{ mt: { xs: 0, md: 3 } }} 
              >
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  image={Event15}
                  icon="price_change"
                  title=""
                  color="error"
                  direction="center"
                  description="Creating your design from scratch with dedicated designers can be very expensive. Start with our Design System."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard 
                  image={Event16}
                  icon="flip_to_front"
                  title=""
                  color="success"
                  direction="center"
                  description="The world's most popular react components library for building user interfaces."
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
    <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Service;
