import * as React from 'react';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Fab from '@mui/material/Fab';
import { Paper, Button } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import { Link } from "react-router-dom";


import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";
import MuiLink from "@mui/material/Link";

import DefaultNavbar from "components/Navbars/DefaultNavbar";
import SimpleFooter from "components/Footers/SimpleFooter";
import DefaultFooter from "components/Footers/DefaultFooter";

import routes from "routes";
import footerRoutes from "footer.routes";

import Service from "./sections/Service";
import Product from "./sections/Product";
import AboutUs from "layouts/pages/about-us";

import bgImage from "assets/images/cus1.jpg";
import Maintainance from "assets/images/general/maintenance.png"

const fabStyle = {
    position: 'fixed',
    bottom: 16,
    right: 16,
    width: 80,
    height: 80,
    backgroundColor: "green"
  };

function Shop() {

    const [open, setOpen] = React.useState(true);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: 5,
        p: 4,
      };
      
    return (
      <div>
        <MKBox variant="gradient" bgColor="white" shadow="sm" py={0.25}>
          <DefaultNavbar
            routes={routes}
            action={{
              type: "external",
              route: "",
              label: "cart",
              color: "warning",
            }}
            transparent
            relative
            center
          />
        </MKBox>
        <MKBox
            minHeight="35vh"
            width="100%"
            sx={{
            backgroundImage: `url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "top",
            display: "grid",
            placeItems: "center",
            }}
        >
        </MKBox>

        <MKBox component="section" py={6} bgColor= "grey-200">
          <Container>
            <Grid
              container
              item
              xs={12}
              lg={6}
              flexDirection="column"
              alignItems="center"
              sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
            >
              <MKBadge
                variant="gradient"
                color="secondary"
                badgeContent="WE PRINT"
                container
                sx={{ mb: 3 , color:"dark"}}
                size="md"
              />
              <MKTypography variant="h2" fontWeight="bold" color= "white">
                Exactly what you need
              </MKTypography>
              <MKTypography variant="body2" color="white" mt={2}>
                proudly providing high quality products and services in Malaysia since 1995. What differentiates us from other businesses is our ability to create any product from scratch and truly connect with our customers, and provide the exceptional, compassionate service they deserve. To learn more, simply browse through our site.
              </MKTypography>

              <Link to="/pages/pages/about-us">
                <MKButton
                  component={MuiLink}
                  target="_blank"
                  rel="noreferrer"
                  variant="gradient"
                  size="large"
                  color="secondary"
                  circular="true"
                  sx={{ mt:3 }}
                >
                    <MKTypography 
                      variant="h7" 
                      color="dark" >
                      About Us
                    </MKTypography>

                </MKButton>
              </Link>
            </Grid>
          </Container>
        </MKBox>
        
        <Service />
        <Product />
        <MKBox pt={6} px={1} mt={6}>
           <DefaultFooter content={footerRoutes} />
        </MKBox>
        <Fab sx={fabStyle} color="secondary" aria-label="add" href="https://wa.me/60162091127?text=Hi,%20We%20need%20your%20service">
            <WhatsAppIcon fontSize="large"/>
        </Fab>
      </div>
    );
}


function Item(props)
{
    return (
        <Paper>
            <h2>{props.item.name}</h2>
            <p>{props.item.description}</p>

            <Button className="CheckButton">
                Check it out!
            </Button>
        </Paper>
    )
}
export default Shop;