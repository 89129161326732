/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

import AboutInfoCard from "components/Cards/InfoCards/AboutInfoCard";
import CenteredBlogCard from "components/Cards/BlogCards/CenteredBlogCard";

import CreditCardIcon from '@mui/icons-material/CreditCard';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import LoyaltyIcon from '@mui/icons-material/Loyalty';

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} justifyContent="center" alignItems="center">
          <Grid item xs={12} lg={12}>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <AboutInfoCard
                    icon={<LoyaltyIcon/>}
                    title="Shopping Consultation"
                    description="We offer reliable product consultation what suite your printing and designing need"
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <AboutInfoCard
                    icon={<LocalShippingIcon/>}
                    title="Scheduled Delivery"
                    description="We got you covered with scheduled delivery, currently covering CyberJaya and Kuala Lumpur"
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <AboutInfoCard
                    icon={<LocalPhoneIcon/>}
                    title="Dial In Service"
                    description="You may save our contact and call / whatsapp us on your printing needs"
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <AboutInfoCard
                    icon={<CreditCardIcon/>}
                    title="Cashless Payment"
                    description="We offer cashless payments of any amount"
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image="https://images.unsplash.com/photo-1544717302-de2939b7ef71?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
              title="Get insights on Search"
              description="Website visitors today demand a frictionless user expericence — especially when using search. Because of the hight standards."
              action={{
                type: "internal",
                route: "pages/company/about-us",
                color: "info",
                label: "find out more",
              }}
            />
          </Grid> */}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
