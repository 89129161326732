import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";
//from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function DefaultInfoCard({ color, icon, title, description, direction, small,image }) {
  return (
    <MKBox 
      lineHeight={1} 
      p={direction === "center" ? 2 : 0} 
      borderRadius = "10"
      textAlign={direction}
      ml={-1}
      mr={1}
      >
      <img
        src={`${image}?auto=format`}
        srcSet={`${image}?auto=format&dpr=2 2x`}
        alt={title}
        loading="lazy"
        width={"100%"}
        style={{
          borderRadius: 5
        }}
      />
      <MKTypography
        display="block"
        variant="h6"
        fontWeight="regular"
        mt={direction === "center" ? 1 : 2}
        mb={1.5}
      >
        {title}
      </MKTypography>
    </MKBox>
  );
}

// Setting default props for the DefaultInfoCard
DefaultInfoCard.defaultProps = {
  color: "info",
  direction: "left",
  small: false,
};

// Typechecking props for the DefaultInfoCard
DefaultInfoCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  direction: PropTypes.oneOf(["left", "right", "center"]),
  small: PropTypes.bool,
  image: PropTypes.node.isRequired
};

export default DefaultInfoCard;
